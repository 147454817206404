import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import IconCustom from 'gatsby-theme-dettol/src/components/common/IconCustom';
import './ProductFeatureItem.scss';
import getDropDownItem from 'gatsby-theme-dettol/src/utils/dropDownControl';

const ProductFeatureItem: FC<ProductTypes.TFeatureProps> = ({ description, imageAlias }) => {
  const splitDescription = description.split('*')[0];

  return (
    <Col xs={6} sm={6} md={4} lg={3} xl={3}>
      <div className="dt-features__list-item">
        <span className="dt-features__list-image">
          <IconCustom icon={getDropDownItem(imageAlias)} />
        </span>
        <span className="dt-features__list-item-text">{splitDescription}</span>
      </div>
    </Col>
  );
};

export default ProductFeatureItem;
