import React, { FC } from 'react';
import HTag from 'gatsby-theme-dettol/src/components/common/HTag';
import getDropDownItem from 'gatsby-theme-dettol/src/utils/dropDownControl';
import { IProductFeatureProps } from './models';
import ProductFeatureItem from './ProductFeatureItem/ProductFeatureItem';
import './ProductFeatures.scss';

const ProductFeatures: FC<IProductFeatureProps> = ({ titleBold, titleRegular, features }) => {
    const splitDescription = features?.length ? features[0].properties.description.split('*') : [];
    const disclaimers = splitDescription.slice(1).map((disclaimer) => {
        return `* ${disclaimer}`;
    });

    const featuresList = features?.map((feature, index) => (
        <ProductFeatureItem
            key={`${getDropDownItem(feature.properties.imageAlias)}-${index}`}
            {...feature.properties}
        />
    ));

    return (
        <div className="dt-features">
            <HTag size={3} regularText={titleRegular} boldText={titleBold} />
            <nav className="dt-features__list">{featuresList}</nav>
            {disclaimers?.map((disclaimer, i) => (
                <div className="dt-features__disclaimer" key={i}>
                    {disclaimer}
                </div>
            ))}
        </div>
    );
};

export default ProductFeatures;
